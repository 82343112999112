import React, { useState } from 'react';
import CloseIcon from './close';
import { Button } from "./components/index";
import { motion } from "framer-motion";
import SvgComponent from "./components/svgs";

function Index({onToggle , setOnToggle , _language}) {

    return (
        <motion.div
            style={{
                backgroundColor: "white",
                position : "absolute",
                width: "100svw",
                height: "100svh",
                zIndex: 100,
                opacity : onToggle ? 1 : 0,
                pointerEvents: onToggle ? "auto" : "none",
            }}
            id='page2mobile'
        >   
            <div
                className='backIcon'
                style={{
                    left: "2%",
                    top: "2%",
                }}

                onClick={()=>{
                    setOnToggle(false)
                }}
            >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    style={{
                        width : "100%",
                        height : "100%",
                    }}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                </svg>
            </div>

            <div className='closeIcon'
                onClick={()=>{
                    // send massage to parent
                    window.parent.postMessage("close","*")
                }}
            >
                <div className='leftIcon'/>
                <div className='rightIcon'/>
            </div>




            <div className='block' style={{
                    height : "8%"
            }}/>


            <div className='imgContainer'
                style={{
                    height : "45%",
                    width : "fit-content",
                    margin : "auto",
                }}
            >
                <SvgComponent/>
            </div>

            <div className='block' style={{
                    height : "5%"
            }}/>

            
            <ol className="measurement-list"
                style={{
                    listStyleType: "none",
                }}
            >
                <li>
                    <div className='listStyle'>1</div>
                    <div>
                        <p>{_language.page2.waist}</p>
                        <div className='blockLi'/>
                        <p>{_language.page2.theslimmestpart.split("<br/>").map((item)=>{
                            return <>{item}<br/></>
                        })}</p>
                    </div>
                </li>            

                <div className='blockLi'/>

                <li>
                    <div className='listStyle'>2</div>

                    <div>
                        <p>{_language.page2.hips}</p>
                        <div className='blockLi'/>
                        <p>{_language.page2.thefullestpart.split("<br/>").map((item)=>{
                            return <>{item}<br/></>
                        })}</p>
                    </div>
                </li> 
            </ol>
        </motion.div>
  );
}

export default Index;


// position: absolute;
// top: 1.5svh;
// right: 1.5svh;


// product data , result
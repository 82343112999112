import {useSuperfan} from "@pikabobalex/superfan-module"

export const Button = ({children , ...rest}) => {
    const { recordEvent } = useSuperfan();
    const {onClick = ()=>{} , id = null} = rest;
    return(
        <div
            style={{
                width : "fit-content",
                margin : "auto",
            }}
        >
            <button
                className="find_btn"
                style={{
                    padding : "clamp(0.35rem, 0.7svh, 0.8rem) 0",
                    width : "clamp(8.5rem, 17svh, 15rem)",
                    fontFamily : "Klein-65Medium",
                    fontWeight : "500"
                }}

                onClick={()=>{
                    onClick();
                    if(id !== null){
                        recordEvent(id);
                    }
                }}
            >
                {children}
            </button>
        </div>
    )
}


export const Button2 = ({children , ...rest}) => {
    const {onClick = ()=>{} , id = null} = rest;
    const { recordEvent } = useSuperfan();

    return(
        <div
            style={{
                width : "fit-content",
                margin : "auto",
            }}
        >
            <button
                className="find_btn find_btn2"
                style={{
                    width : "clamp(5.5rem, 8svh, 8rem)",
                    height : "clamp(5.5rem, 8svh, 8rem)",
                    borderRadius : "50%",
                }}

                onClick={()=>{
                    onClick();
                    if(id !== null){
                        recordEvent(id);
                    }
                }}
            >
                {children}
            </button>
        </div>
    )
}

export const Button3 = ({children , ...rest}) => {
    const {onClick = ()=>{}} = rest;
    return(
        <div
            style={{
                width : "fit-content",
                margin : "auto",
            }}
        >
            <button
                style={{
                    width : "clamp(5.5rem, 8svh, 8rem)",
                }}

                onClick={()=>{
                    onClick();
                }}
            >
                {children}
            </button>
        </div>
    )
}

export const Measurement = {
    "asiamen" : {
        max : {
            waist : 111,
            hip : 127,
        },
        min : {
            waist : 71,
            hip : 87,
        },
        28 : {
            "height" : 166,
            "waist" : 71,
            "hip" : 87,
        },
        29 : {
            "height" : 168,
            "waist" : 73.5,
            "hip" : 87,
        },
        30 : {
            "height" : 170,
            "waist" : 76,
            "hip" : 92,
        },
        31 : {
            "height" : 173,
            "waist" : 78.5,
            "hip" : 94.5,
        },
        32 : {
            "height" : 175,
            "waist" : 81,
            "hip" : 97,
        },
        33 : {
            "height" : 178,
            "waist" : 83.5,
            "hip" : 99.5,
        },
        34 : {
            "height" : 180,
            "waist" : 86,
            "hip" : 102,
        },
        36 : {
            "height" : 182,
            "waist" : 91,
            "hip" : 107,
        },
        38 : {
            "height" : 184,
            "waist" : 96,
            "hip" : 112,
        },
        40 : {
            "height" : 185,
            "waist" : 101,
            "hip" : 117,
        },
        42 : {
            "height" : 185,
            "waist" : 106,
            "hip" : 122,
        },
        44 : {
            "height" : 185,
            "waist" : 111,
            "hip" : 127,
        },
    },
    "asiawomen" : {
        max : {
            waist : 90,
            hip : 116,
        },
        min : {
            waist : 59,
            hip : 85,
        },
        24 : {
            "height" : 157,
            "waist" : 59,
            "hip" : 85,
        },
        25 : {
            "height" : 160,
            "waist" : 61.5,
            "hip" : 87.5,
        },
        26 : {
            "height" : 165,
            "waist" : 64,
            "hip" : 90,
        },
        27 : {
            "height" : 168,
            "waist" : 66.5,
            "hip" : 92.5,
        },
        28 : {
            "height" : 180,
            "waist" : 69,
            "hip" : 95,
        },
        29 : {
            "height" : 182,
            "waist" : 71.5,
            "hip" : 97.5,
        },
        30 : {
            "height" : 184,
            "waist" : 74,
            "hip" : 100,
        },
        31 : {
            "height" : 184,
            "waist" : 77.8,
            "hip" : 103.8,
        },
        32 : {
            "height" : 184,
            "waist" : 81.6,
            "hip" : 107.6,
        },
        33 : {
            "height" : 184,
            "waist" : 85.4,
            "hip" : 111.4,
        },
        34 : {
            "height" : 184,
            "waist" : 89.2,
            "hip" : 115.2,
        },
    },
    "usmen" : {
        min : {
            waist : 76,
            hip : 90,
        },
        max : {
            waist : 99,
            hip : 113,
        },
        29 : {
            "height" : 168,
            "waist" : 76,
            "hip" : 90,
        },
        30 : {
            "height" : 170,
            "waist" : 79,
            "hip" : 93,
        },
        31 : {
            "height" : 173,
            "waist" : 81,
            "hip" : 95,
        },
        32 : {
            "height" : 175,
            "waist" : 84,
            "hip" : 98,
        },
        33 : {
            "height" : 178,
            "waist" : 86,
            "hip" : 100,
        },
        34 : {
            "height" : 180,
            "waist" : 89,
            "hip" : 103,
        },
        36 : {
            "height" : 182,
            "waist" : 94,
            "hip" : 108,
        },
        38 : {
            "height" : 184,
            "waist" : 99,
            "hip" : 113,
        },
    },
    "uswomen" : {
        min : {
            waist : 61,
            hip : 85,
        },
        max : {
            waist : 92,
            hip : 118,
        },
        24 : {
            "height" : 157,
            "waist" : 61,
            "hip" : 86.5,
        },
        25 : {
            "height" : 160,
            "waist" : 63.5,
            "hip" : 89,
        },
        26 : {
            "height" : 165,
            "waist" : 66,
            "hip" : 91.5,
        },
        27 : {
            "height" : 168,
            "waist" : 68.5,
            "hip" : 94,
        },
        28 : {
            "height" : 180,
            "waist" : 71,
            "hip" : 96.5,
        },
        29 : {
            "height" : 182,
            "waist" : 73.5,
            "hip" : 99,
        },
        30 : {
            "height" : 184,
            "waist" : 76,
            "hip" : 101.5,
        },
        31 : {
            "height" : 184,
            "waist" : 80,
            "hip" : 105.5,
        },
        32 : {
            "height" : 184,
            "waist" : 84,
            "hip" : 109,
        },
        33 : {
            "height" : 184,
            "waist" : 87.5,
            "hip" : 113,
        },
        34 : {
            "height" : 184,
            "waist" : 91.5,
            "hip" : 117,
        },
    }
}

export function FitFinderPans( waist , hip , type = "asiamen" , product = "null"){
    let keySize = null;
    let closeestDistance = Infinity;

    let arr = Object.keys(Measurement[type]);
    arr = arr.filter((item)=>{
        return item !== "max" && item !== "min";
    })

    arr.forEach((key)=>{
        let current_hips = Measurement[type][key].hip;
        if(hip >= current_hips){
            let distance = Math.sqrt(Math.pow(hip - current_hips , 2) + Math.pow(waist - Measurement[type][key].waist , 2));
            if(distance < closeestDistance){
                closeestDistance = distance;
                keySize = key;
            }
        }
    })
    
    let fitted , baggy;
    let routeType;
    if(["J223725","J223458"].includes(product) && type.includes("women")){
        routeType = "routeUp"
    }
    else{
        routeType = "routeDown"
    }

    if(routeType === "routeUp"){
        fitted = keySize;
        let index = arr.indexOf(keySize);
        if(index + 1 < arr.length){
            baggy = arr[index + 1];
        }
        else{
            baggy = keySize;
            fitted = keySize;
        }    
    }
    else{
        baggy = keySize;
        let index = arr.indexOf(keySize);
        if(index - 1 >= 0){
            fitted = arr[index - 1];
        }
        else{
            fitted = keySize;
            baggy = keySize;
        }
    }

    let _ = {
        fitted : fitted,
        baggy : baggy,
        closeestDistance : closeestDistance,
        your_hip : hip,
        your_waist : waist,
        product_hip : Measurement[type][keySize]?.hip ?? null,
        product_waist : Measurement[type][keySize]?.waist ?? null,
    }

    return _
}
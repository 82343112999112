import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    viewBox="0 0 167.96 389.9"
    style={{
        height : "100%",
        margin : "auto",
        width : "fit-content",
    }}
  >
    <defs>
      <style>
        {
          ".cls-1{stroke-width:.5px}.cls-1,.cls-2{font-family:Klein-55Regular,Klein;font-size:10px}.cls-1,.cls-2,.cls-3{isolation:isolate}.cls-1,.cls-4{fill:none;stroke:#303030;stroke-miterlimit:10}.cls-6{stroke-width:0}.cls-2,.cls-6{fill:#303030}"
        }
      </style>
    </defs>
    <g id="Layer_1-2">
      <path
        d="M151.84 186.39s-3.49-25.27-4.66-35.25c-1.16-9.98-.83-11.64-2.16-14.3s-.67-9.64-3.82-23.11c-3.16-13.47-1-16.96-3.66-30.76s-15.8-15.13-22.28-15.46-8.31-1.33-13.97-4.99c-4.84-3.13-4.68-11.76-4.54-14.17.02-.41.15-.79.34-1.15 5.38-9.68 6.01-24.84 5.52-29.74C102.11 12.47 99.65 0 83.99 0S65.87 12.47 65.37 17.46c-.49 4.9.14 20.07 5.52 29.74.2.36.32.74.34 1.15.15 2.41.31 11.03-4.54 14.17-5.65 3.66-7.48 4.66-13.97 4.99S33.1 69.17 30.44 82.97s-.5 17.29-3.66 30.76-2.49 20.45-3.82 23.11-1 4.32-2.16 14.3-4.66 35.25-4.66 35.25S-.49 197.53.01 202.19s7.9-2.33 7.9-2.33-3.91 17.87 2.83 20.7c6.74 2.83 15.05-7.57 14.88-31.43 0 0 14.13-31.01 17.13-45.97 0 0 6.32-18.29 6.65-33.59 0 0 1.16 14.96 2.49 23.94 1.33 8.98-.33 40.57-1.66 45.39-1.33 4.82-3.33 13.14-3.49 25.27-.17 12.14-.67 40.9 3.16 49.71 3.82 8.81 5.82 15.8 6.48 25.27.67 9.48-.79 20.45-1.04 25.65-.16 3.32.37 10.93 1.54 17.58s9.53 29.04 9.14 35.08c-.27 4.2-3.49 15.13-6.48 18.46-2.67 2.96-5.47 6.46-5.93 11.07-.11 1.12.67 2.13 1.77 2.36 1.79.39 5.17.74 11.31.37 10.97-.67 12.64-2.16 12.8-4.82s-1.66-10.48-2.49-14.8c-.83-4.32.17-16.13.5-22.28s3.26-34.29 2.74-40.49c-.58-6.98-2.41-15.55-2.08-24.86s.83-16.29 1.33-26.44c.5-10.14 1.83-26.27 2.16-44.39h4.66c.33 18.12 1.66 34.25 2.16 44.39.5 10.14 1 17.13 1.33 26.44s-1.5 17.87-2.08 24.86c-.52 6.2 2.41 34.33 2.74 40.49.33 6.15 1.33 17.96.5 22.28s-2.66 12.14-2.49 14.8c.17 2.66 1.83 4.16 12.8 4.82 6.14.37 9.52.02 11.31-.37 1.1-.24 1.88-1.25 1.77-2.36-.46-4.61-3.26-8.1-5.93-11.07-2.99-3.33-6.21-14.26-6.48-18.46-.39-6.04 7.98-28.43 9.14-35.08 1.16-6.65 1.7-14.26 1.54-17.58-.25-5.2-1.7-16.17-1.04-25.65s2.66-16.46 6.48-25.27c3.82-8.81 3.33-37.58 3.16-49.71s-2.16-20.45-3.49-25.27-2.99-36.41-1.66-45.39c1.33-8.98 2.49-23.94 2.49-23.94.33 15.3 6.65 33.59 6.65 33.59 2.99 14.96 17.13 45.97 17.13 45.97-.17 23.86 8.15 34.25 14.88 31.43 6.73-2.83 2.83-20.7 2.83-20.7s7.4 6.98 7.9 2.33c.5-4.66-16.13-15.8-16.13-15.8h.02Z"
        style={{
          strokeWidth: 0,
          fill: "#f6f6f6",
        }}
      />
      <path d="M52.32 160.62h22.87" className="cls-4" />
      <path d="M51.82 157.37h1v6.5h-1z" className="cls-6" />
      <path d="M92.37 160.62h23.13" className="cls-4" />
      <path d="M115 157.37h1v6.5h-1z" className="cls-6" />
      <path d="M46.81 201.52h28.38" className="cls-4" />
      <path d="M46.31 198.27h1v6.5h-1z" className="cls-6" />
      <path d="M92.37 201.52h28.76" className="cls-4" />
      <path d="M120.63 198.27h1v6.5h-1z" className="cls-6" />
      <g className="cls-3">
        <text className="cls-2" transform="translate(82.4 163.5)">
          <tspan x={0} y={0}>
            {"1"}
          </tspan>
        </text>
        <text className="cls-1" transform="translate(82.4 163.5)">
          <tspan x={0} y={0}>
            {"1"}
          </tspan>
        </text>
      </g>
      <g className="cls-3">
        <text className="cls-2" transform="translate(81.24 204.51)">
          <tspan x={0} y={0}>
            {"2"}
          </tspan>
        </text>
        <text className="cls-1" transform="translate(81.24 204.51)">
          <tspan x={0} y={0}>
            {"2"}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
)
export default SvgComponent

import React, { useEffect, useState } from 'react';
import './App.css';
import CloseIcon from './close';
import Page1 from './page1';
import Page2 from './page2';
import Page2Mobile from './page2mobile';
import { language } from './language';
function App() {
  const [onToggle , setOnToggle] = useState(false)
  const [_language , setLanguage] = useState(language["en"])
  useEffect(() => {
    let seachParams = new URLSearchParams(window.location.search);
    let _leng = seachParams.get('language');
    if(_leng === 'en'){
      setLanguage(language["en"])
    }
    else if(_leng === 'hk'){
      setLanguage(language["hk"])
    }
    else if(_leng === 'tw'){
      setLanguage(language["tw"])
    }
    else if(_leng === 'kr'){
      setLanguage(language["kr"])
    }
    else if(_leng === 'jp'){
      setLanguage(language["jp"])
    }
    else{
      setLanguage(language["en"])
    }
  }, []);
  
  return (
    <div className="container">
      <div
        className='absoluteControl'
      >
        <Page1 onToggle = {onToggle} setOnToggle = {setOnToggle} _language = {_language} setLanguage = {setLanguage}/>

        <Page2 onToggle = {onToggle} setOnToggle = {setOnToggle} _language = {_language}/>


        <Page2Mobile onToggle = {onToggle} setOnToggle = {setOnToggle} _language = {_language}/>
      </div>
    </div>
  );
}

export default App;

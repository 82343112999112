import React, { useState } from 'react';
import CloseIcon from './close';
import { Button } from "./components/index";
import { motion } from "framer-motion";
import SvgComponent from "./components/svgs";

function Index({onToggle , setOnToggle , _language}) {
    const [_toggle , setToggle] = useState(false)

    return (
        <motion.div
            style={{
                backgroundColor: "white",
                position : "relative",
                minWidth: "0",
            }}

            className='side_container page2'

            initial={{
                width: 0,
            }}


            animate={
                onToggle? 
                {
                    width: "37.7svh",
                } :
                {
                    width: 0,
                    opacity: 0,
                }
            }

            transition={{
                duration: 0.3,
                type: onToggle ? "ease-in-out" : "ease-out-in",
                delay: onToggle ? 0 : 0.3
            }}

            // animate end
            onAnimationComplete={() => {
                console.log("Animation Completed");
                setToggle(onToggle)
            }}
        >   
            {/* back icon */}
            <div
                className='backIcon'
                style={{
                    left: "2%",
                    top: "2%",
                    opacity: _toggle && onToggle ? 1 : 0,
                    transition: _toggle && onToggle ? "opacity 0.5s ease 0.3s"  : "0.3s"// Add this line    
                }}

                onClick={()=>{
                    setOnToggle(false)
                }}
            >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    style={{
                        width : "100%",
                        height : "100%",
                    }}
                >
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                </svg>
            </div>

            <div className='closeIcon'
                onClick={()=>{
                    // send massage to parent
                    window.parent.postMessage("close","*")
                }}
                style={{
                    opacity: _toggle && onToggle ? 1 : 0,
                    transition: _toggle && onToggle ? "opacity 0.5s ease 0.3s"  : "0.3s"// Add this line
                }}
            >
                <div className='leftIcon'/>
                <div className='rightIcon'/>
            </div>




            <div className='line' style={{
                height : "90%",
                position: "absolute",
                left: "0%",
                top: "50%",
                transform: "translate(0%, -50%)",
                width: "0.1svh",
                backgroundColor: "black",
                opacity: _toggle && onToggle ? 1 : 0,
                transition: _toggle && onToggle ? "opacity 0.5s ease 0.3s"  : "0.3s"// Add this line
            }} />

            <div className='block' style={{
                    height : "8%"
            }}/>


            <div className='imgContainer'
                style={{
                    height : "45%",
                    opacity: _toggle && onToggle ? 1 : 0,
                    transition: _toggle && onToggle ? "opacity 0.5s ease 0.5s" : "0.3s",
                    width : "fit-content",
                    margin : "auto",
                }}
            >
                <SvgComponent/>
            </div>

            <div className='block' style={{
                    height : "5%"
            }}/>

            
            <ol className="measurement-list"
                style={{
                    opacity: _toggle && onToggle ? 1 : 0,
                    transition: _toggle && onToggle ? "opacity 0.5s ease 0.5s" : "0.3s",
                    listStyleType: "none",
                }}
            >
                {/* <li>
                    <div className='listStyle'>1</div>
                    <div>
                        <p>{_language.page2.shoulder}</p>
                        <div className='blockLi'/>
                        <p>{_language.page2.fromshouldertip.split("<br/>").map((item)=>{
                            return <>{item}<br/></>
                        })}</p>
                    </div>
                </li>

                <li>
                    <div className='listStyle'>2</div>
                    <div>
                        <p>{_language.page2.chest}</p>
                        <div className='blockLi'/>
                        <p>{_language.page2.thefirstpart.split("<br/>").map((item)=>{
                            return <>{item}<br/></>
                        })}</p>
                    </div>
                </li>             */}


                <li>
                    <div className='listStyle'>1</div>
                    <div>
                        <p>{_language.page2.waist}</p>
                        <div className='blockLi'/>
                        <p>{_language.page2.theslimmestpart.split("<br/>").map((item)=>{
                            return <>{item}<br/></>
                        })}</p>
                    </div>
                </li>            

                <div className='blockLi'/>

                <li>
                    <div className='listStyle'>2</div>

                    <div>
                        <p>{_language.page2.hips}</p>
                        <div className='blockLi'/>
                        <p>{_language.page2.thefullestpart.split("<br/>").map((item)=>{
                            return <>{item}<br/></>
                        })}</p>
                    </div>
                </li>            
            </ol>
        
        </motion.div>
  );
}

export default Index;


// position: absolute;
// top: 1.5svh;
// right: 1.5svh;
